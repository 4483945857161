div.dropzone
{
  /* positions to point 0,0 - required for z-index */
  position: fixed; top: 0; left: 0;
  /* above all elements, even if z-index is used elsewhere
     it can be lowered as needed, but this value surpasses
     all elements when used on YouTube for example. */
  z-index: 9999999999;
  /* takes up 100% of page */
  width: 100%; height: 100%;
  /* dim the page with 50% black background when visible */
  background-color: rgba(0,0,0,0.5);
  /* a nice fade effect, visibility toggles after 175ms, opacity will animate for 175ms. note display:none cannot be animated.  */
  transition: visibility 175ms, opacity 175ms;
}

.custom_md img {
  max-height: 80vh;
  display: block;
  margin: 0 auto;
  padding: 25px;
}