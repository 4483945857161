.noStreams {
  width: 100%;
  text-align: center;

  h5 {
    font-size: 2rem;
  }

  @media (max-width: 1000px) {
    h5 {
      font-size: 1.5rem;
    }
  }
}

.reload {
  margin: 50px 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  .reloadControl {
    display: flex;
    align-items: center;
    gap: 10px;

    margin-left: auto;
  }

  .formControl {
    min-width: 120px;
  }

  @media (max-width: 575px) {
    & {
      flex-direction: column;
      align-items: flex-end;
    }
  }
}

.search {
  margin: 20px 0 30px;
}

.sortHeaders {
  padding: 0 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .statusLabel {
    margin-left: 90px;
  }
}

.streamItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
  padding: 10px 20px;
  margin: 20px 0;

  .streamName {
    width: 350px;
    text-align: left;
  }
}

@media (max-width: 1000px) {
  .sortHeaders {
    flex-direction: column;

    .statusLabel {
      margin-left: 0;
    }
  }

  .streamItem {
    flex-direction: column;
    gap: 20px;

    .streamName {
      width: auto;
      text-align: center;
    }

    & > div {
      flex-direction: column;
      gap: 15px;

      p {
        margin: 0;
      }
    }
  }
}
