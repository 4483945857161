label {
  font-size: 16px;
  display: block;
}

button,
input {
  padding: 4px;
  border: 1px solid var(--disabled-color);
}
.tool-bar {
  padding: 0 3rem 0 5rem !important;
  display: flex !important;
  justify-content: space-between !important;
}
button {
  outline: none;
  background: transparent;
  border-radius: 5px;
  color: var(--primary-color);
  transition: all ease 0.8s;
  cursor: pointer;
}

button.active {
  color: var(--primary-color);
}

button.active:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50%;
  padding-top: 4px;
  border-bottom: 1px solid var(--primary-color);
}

input:focus {
  outline: none;
}

select {
  outline: none;
  height: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.col-wrapper {
  display: flex;
  flex-direction: column;
  margin: 20px;
  padding: 20px;
  background-color: var(--background-color);
  border-radius: 5px;
}

.col-header {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 0;
}

.col-title {
  margin: 0.5rem 0;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header {
  height: 65px;
}
.col {
  min-height: 300px;
  max-width: 300px;
  width: 300px;
}
.page-header {
  background-color: #054f7c;
  padding: 20px;
  color: white;
  font-size: 30px;
  flex: 1 100%;
  margin-top: 0;
  text-align: center;
}
.drop-wrapper {
  flex: 1 25%;
  width: 100%;
  height: 80%;
  min-height: 400px;
  overflow-y: auto;
}
.task {
  font-size: 12px;
  padding: 12px 14px;
  border-radius: 10px;
  z-index: 1;
  background-color: #1d1d1d;
  color: #fff;
  margin: 0 0 10px 0;
}

.task:hover {
  cursor: pointer;
}

.task-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  padding-bottom: 20px;
  word-break: break-word;
}

.task-name {
  margin: 0;
  display: flex;
}

.task-status {
  text-align: right;
}

.color-bar {
  width: 15px;
  height: 15px;
  border-radius: 15px;
}

.modal {
  background-color: #2c2c2c;
  border-radius: 2px;
  margin: 48px 0 80px;
  height: fit-content;
  width: 860px;
  display: flex;
  flex-wrap: wrap;
  outline: none;
  padding: 20px;
  justify-content: space-between;
  border-radius: 15px;
}

.overlay {
  z-index: 1101;
  display: flex;
  justify-content: center;
  position: fixed;
  overflow: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.close-btn {
  cursor: pointer;
}
.close-btn:hover {
  transition: 0.2s;
  filter: opacity(0.7);
}
.task-close-btn {
  height: 24px;
  width: 35px;
  font-size: 13px;
  color: #031d2c;
  border: none;
  border-radius: 25px;
}
.task-close-btn:hover {
  background-color: #dcdcdc;
}

.task-bottom {
  display: flex;
  justify-content: space-between;
}
.task-icons-bottom {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.task-bottom-order {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.persons-icons {
  width: 24px;
  height: 24px;
  background-color: green;
  border-radius: 24px;
}
.task-persons-icons {
  display: flex;
  width: 54px;
  justify-content: space-between;
}
.task-date {
  display: flex;
  justify-content: flex-end;
  margin: 0;
  font-size: 0.82rem;
  color: #939393;
}
.task-id {
  margin: 0;
  font-size: 0.82rem;
  color: #939393;
}
.task-modal-description {
  display: flex;
  align-items: center;
}

.task-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dot {
  height: 15px;
  width: 15px;
  margin-left: 0.25rem;
  border-radius: 50%;
  display: inline-block;
}

.image-preview {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  margin: 0.5rem;
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
}
.image-preview:hover {
  cursor: pointer;
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.file-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 0.5rem;
}
.page {
  width: 100%;
}
.note-button {
  height: 40px;
  margin: 0 5px;
  border-radius: 7px;
  background: #323232;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  padding: 10px;
}
.note-button-red {
  background: #6f0000;
}
