.account_item {
  width: 50px;
  height: 23px;
  border-radius: 7px;
  color: #000;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px;
  cursor: pointer;
  &_active {
    background: #00ff6f;
  }
  &_pending {
    background: #fff500;
  }
  &_inactive {
    background: #ff0000;
  }
  &_unwatched {
    background: #e3e3e3;
    color: #000;
  }
}
