a {
  color: #ffffff;
}
.taskModalLeft {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  flex-wrap: wrap;
  flex: 52% 0 0;
}
.taskModalRight {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 0 0 45%;
  width: 370px;
}
.taskModalRightTop > p:first-child {
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
}
.bgInput {
  background: #353535;
  padding: 8px 10px !important;
  outline: none;
  border-radius: 9px;
  border-bottom: none !important;
}
.bgInputAutocompleteInput {
  background: #353535;
  padding: 1.5px -1px !important;
  outline: none;
  border-radius: 9px;
  border-bottom: none !important;
}
.bgInputAutocomplete {
  background: #353535;
  padding: 2px 10px !important;
  outline: none;
  border-radius: 9px;
  border-bottom: none !important;
}
.taskModalName {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.taskModalTitle {
  font-size: 22px !important;
  width: 360px;
  height: 42px;
  line-height: 26px;
  justify-content: center;
}
.taskModalInfo {
  margin: 30px 0 10px 0;
  display: flex;
  justify-content: space-between;
}
.taskModalLink {
  width: 345px;
  height: 35px;
  margin: 0;
  line-height: initial;
  justify-content: center;
  overflow: hidden;
  white-space: nowrap;
}
.taskModalLink p {
  margin: 0;
  line-height: initial;
}
.taskModalDrive {
  font-size: 16px !important;
  height: 35px;
  width: 68px;
  line-height: 26px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: #414141 !important;
}
.taskModalDrive > p {
  margin: 0;
}
.taskModalId {
  margin: 10px 0 0 0;
  font-style: italic;
}
.taskModalSelectPerson {
  display: flex;
  width: 100%;
  margin: 10px 0;
  justify-content: space-between;
}
.taskModalExecutor {
  height: 35px !important;
  width: 48.6% !important;
}
.taskModalUserSelect {
  height: 22rem;
}
.taskModalTagSelect {
  display: flex;
  align-items: center;
  height: 35px !important;
}
.taskModalFiles {
  list-style: none;
  display: flex;
  justify-content: space-between;
  margin: 0;
  margin-top: 10px;
}
.taskModaDescription {
  white-space: pre-wrap;
  word-break: break-all;
  overflow: scroll;
  cursor: pointer;
  max-height: 300px;
  min-height: 130px;
  margin: 0;
  margin-bottom: 30px;
}
.taskModaDescription > p {
  margin: 0;
}
.taskModalFileName {
  color: #989898;
  text-decoration: none;
}
.taskModalFileName:hover {
  transition: 0.2s;
  color: #ffffff;
}
.taskModalFilesRow {
  display: flex;
  align-items: center;
}
.taskModalFilesRow > img {
  padding-right: 10px;
  max-width: 60px;
}
.dropMedia-area-active {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropMedia-area {
  color: #7d7d7d;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  img {
    padding-right: 10px;
  }
}
.uploadFilesBtn {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  text-decoration: underline 1px !important;
}
.taskModalLog > p {
  margin: 0;
}
.taskModalRightTop {
  height: 12.2rem;
}
.taskModalComments {
  height: 14rem;
  overflow-y: auto;
  margin-top: 0.5rem;
}
.taskModalRightBottom > p {
  margin: 0;
  margin-bottom: 10px;
  font-weight: bold;
}
.taskModalComment {
  margin-bottom: 1rem;
  background-color: #414141;
  border-radius: 9px;
  padding: 10px 15px;
  max-width: 360px;
}

.taskModalComment > div:first-child {
  float: left;
  margin-right: 1rem;
}

.taskModalCommentText {
  word-break: break-word;
  flex-grow: 1;
  margin-top: 0.5rem;
}

.taskModalCommentUser {
  margin-top: 0.3rem;
}
