.account_day {
  max-width: 300px;
  width: 100%;
  background: rgba(0, 0, 0, 0.74);
  border-radius: 12px;
  margin-right: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.account_day_group {
  width: 100%;
  p {
    text-align: left;
    font-size: 14px;
    padding: 0;
    padding: 10px 0;
    margin: 0;
  }
}
.accounts_row {
  display: flex;
  flex-wrap: wrap;
  min-height: 50px;
  width: 100%;
}
