.react-calendar {
  background: #616161 !important;
  padding: 20px;
  overflow: hidden !important;
  z-index: 1001 !important;
  position: relative;
}
.react-date-picker__calendar--open {
  z-index: 1000;

  position: relative;
}
