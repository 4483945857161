.card_1 {
  background: #5ff56e;
}
.card_2 {
  background: #5bc3ef;
}
.card_3 {
  background: #fdd95e;
}
.card_4 {
  background: #f3a958;
}
.card_5 {
  background: #ce1638;
  color: #fff;
}
