.capItem {
  &--wrapper {
    text-align: left;

    padding: 0 5px;
  }

  &--network {
    width: 100px;
    padding: 0 10px;
    text-align: left;
  }

  &--keyword {
    width: 50px;
  }

  &--dates {
    width: 100px;
  }

  &-paper {
    width: 30%;
  }
}

@media screen and (max-width: 700px) {
  .capItem-paper {
    width: 100%;
    height: 100%;
  }
}
