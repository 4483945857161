.g-table {
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background: #000;
  &--buyer {
    width: 450px;
    margin-right: 24px;
  }
  &--contractor {
    width: 450px;
    margin-right: 24px;
  }
  &--tags {
    width: 322px;
  }
  height: fit-content;
}

.g-table-head {
  padding: 12px 24px;
}
.g-table-add {
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
