.cards_day {
  max-width: 300px;
  min-height: 500px;
  width: 100%;
  background: rgba(0, 0, 0, 0.74);
  border-radius: 12px;
  margin-right: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.card_group {
  margin-top: 30px;
}
.card_title {
  text-align: center;
  width: 100%;
}
