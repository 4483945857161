.pixelPaper {

  margin: 0 auto;
  min-height: 500px;
  max-height: 80vh;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.pixelItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin: 20px 0;
}

.popover1 > div{
  overflow: visible !important;
  overflow-y: visible !important;
}
.pixelDbRow {
  background: #181516c7;
  &:hover {
    background-color: #404040;
  }
}
@media screen and (max-width: 700px) {
  .pixelItem {
    flex-direction: column;
    padding: 0px 10px;
  }
  .pixelTable {
    width: 100% !important;
    max-width: 100%;
    padding: 0 10px;
    margin: 0;
  }
}