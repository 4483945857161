.g-acc-table-head {
  background: #000;
}

.g-acc-table-container {
  overflow-x: scroll;
  max-width: 90vw !important;
  border-radius: 12px;
  background: #171717;
}

.g-acc-tag-row {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  min-width: 240px;
}
.g-acc-chip {
  margin-right: 8px;
  margin-bottom: 10px;
  cursor: pointer !important;
}
