.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 1000ms ease-in;
}
#root {
  background: url("./home_background.jpg");
  background-size: cover;
  min-height: 100vh;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.MuiInputBase-input {
  color: #fff !important;
}
.MuiDrawer-root {
  //position: absolute;
  background: transparent;
}

.page-enter {
  opacity: 0;
  transform: translateY(100vh);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
  transform: translateY(0);
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  transform: translateY(100vh);
}
