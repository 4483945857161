.userItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0;
  padding: 15px 0;

  p {
    padding: 0 20px;
  }

  &-buttons-wrapper {
    display: flex;
    justify-content: space-around;
    width: 300px;
    margin-right: 20px;
  }
}

.usersTable {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 30px;
}

.adminPaper {
  padding: 50px 0;
  width: fit-content;
  margin-top: 200px;
  margin: 0 auto;

}

.adminBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 100px;

  button {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 700px) {
  .userItem {
    flex-direction: column;

    &-buttons-wrapper {
      flex-direction: column;
      width: 100%;
      justify-content: center;
      align-items: center;
      margin: 0;
    }
  }

}