.account_round {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 5px;
  &_green {
    background: hsl(146, 100%, 51%);
  }
  &_red {
    background: #ff0000;
  }
  &_yellow {
    background: #fff500;
  }
}
.accounts_count_el {
  display: flex;
  align-items: center;
  color: rgb(192, 190, 190);
  font-size: 15px;
}

.account_days {
  display: flex;
  margin-top: 20px;
}
