.projects_page {
  display: flex;
  justify-content: center;
  width: 100%;

  h1 {
    margin-top: 20px;
    margin-left: -30px;
  }
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: 0 5vw;
}

.projects {
  margin: 10px 0;
  height: auto;
  width: 100%;
  border-radius: 20px;
  padding: 10px 20px;

  .none {
    margin-left: 20px;
  }

  span {
    font-size: 22px;
    display: block;
    margin-bottom: 10px;
  }

  &_item {
    width: auto;
    height: auto;
    border: 1px dashed black;
    border-radius: 20px;
    text-align: center;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all .3s;

    .title {
      margin: 10px 0;
    }

    .preview {
      width: 200px;
      height: auto;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
    }

    &:hover {
      transform: scale3d(1.1, 1.1, 1.1) translateY(-40px);
      background: #ccddff;
    }

    &_add {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }

  &_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    &_my {
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
  }
}

.findBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}